import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components';

import Layout from '../../../components/Layout'
import Grid from '../../../components/Helpers/Grid'

import './style.css';

const DestinationWraper = styled.div`
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 20px;
  height: auto;
  width: 60vw; 
  p {
    margin-top: 20px;
  }
  
  @media screen and (max-width: 960px) {
    width: 90vw; 
  }
`


const ColumnWrapper = styled.div`
  flex-direction: column;
  justify-content: space-between;
`

const ImageWraper = styled.div`
  height: 100%;
  vertical-align: middle; 
  img {
    height: 200px; 
    width: 200px;
    object-fit: cover; 
    border-radius: 50%;
    background-color: #f1f1f1;
    margin-top: 20px;
  }
`

const Flights = () => {

  return (
      <DestinationWraper>
        <Grid columns={2} style={{ marginBottom: 10, textAlign: "center" }}>
          <ColumnWrapper>
            <ImageWraper>
              <StaticImage src="../../../images/travel/flight.png" layout="constrained"  width={300}  height={300} />
            </ImageWraper>
          </ColumnWrapper>
          <ColumnWrapper>
            <h1>Flights</h1>
            <p>The primary international airport in Buenos Aires is <b>Aeropuerto de Ezeiza</b> (Aeropuerto Internacional Ministro Pistarini). It's located about 1 hour from the city center (depending on traffic). Make sure you are using airport code <b>EZE</b> when booking your flights.</p>
            <br/>
            <p>For domestic flights, search for flights from Aeroparque <b>Jorge Newbury</b>. It's located about 20-30 mins from the city center. Make sure you are using airport code <b>AEP</b> when booking your flights.</p>
            <br/>
            <h1>Transport</h1>
            <p>You can use Uber App to get a transport from the airport. Never accept any taxis or transportation offered by people in the airpot.</p>
            <p>Use only the official ones and the one with a local shop inside the airport for example Tienda Leon.</p>
          </ColumnWrapper>
        </Grid>
      </DestinationWraper>
    )
}

// const Covid = () => {

//   return (
//       <DestinationWraper>
//         <Grid columns={2} style={{ gridColumnGap: "32px", marginBottom: 10, textAlign: "center" }}>
//           <ColumnWrapper>
//             <h1>Safety Requirements</h1>
//             <p>1.Proof of full vaccination (2 vaccinations, 2nd one min. 2 weeks before arrival)</p>
//             <p>2. Declaration form within 48 hours of travel [<a href="https://ddjj.migraciones.gob.ar/app/home.php" rel="noreferrer"  target="_blank">Link Here</a>]</p>
//             <p>3. Negative PCR test within 72 hours of travel OR Negative Antigen Test within 48 hours of travel</p>
//             <p>4. Proof of COVID Health Travel Insurance</p>
//             <p>Argentina requires COVID health insurance for all foreign visitors.</p>
//             <p>Make sure you have a travel health insurance for foreign countries (Auslandskrankenverischerung). The insurance company can provide a document stating it covers Covid-related issues. If you want a insurance, that also covers hotel/travel costs in the event of any travel disruption , make sure to purchase such an insurance minimum 30 before the flight to EZE (zusätzliche Reiserücktrittsversicherung).</p>
//           </ColumnWrapper>
//           <ColumnWrapper>
//             <ImageWraper>
//               <StaticImage src="../../../images/travel/covid.png" layout="constrained"  width={300}  height={300} />
//             </ImageWraper>
//           </ColumnWrapper>
//         </Grid>
//       </DestinationWraper>
//     )
// }


const Page = () => {

  return (
    <Layout section="travel" pageTitle="Travel">
      <Flights />
    </Layout>
  )
}

export default Page